import React from "react";
import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThreadsDisplay } from "./components/Treads/ThreadsDisplay";
import axios from "axios";
import { API_BASE } from "./config";
import { Box, CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { ThreadDisplay } from "./components/Thread/ThreadDisplay";

function App() {
    axios.defaults.baseURL = API_BASE;
    const router = createBrowserRouter(
        [
            {
                path: "/",
                element: <ThreadsDisplay />,
            },
            {
                path: "/thread/:threadNo",
                element: <ThreadDisplay />,
            },
        ],
        {
            basename: process.env.PUBLIC_URL,
        }
    );

    const theme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: "#789922",
            },
            secondary: {
                main: "#81a2be",
            },
        },
    });

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        my: "3rem",
                        overflowX: "hidden",
                    }}
                >
                    <RouterProvider router={router} />
                </Box>
            </ThemeProvider>
        </div>
    );
}

export default App;
