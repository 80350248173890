import { Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { useThreads } from "../../utils/data";
import { ThreadPreview } from "./ThreadsPreview";
import { Post } from "../../utils/models";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ScrollToTop from "../SrollToTop";
import { ShowNonStandardButton } from "./ShowNonStandardButton";

export const ThreadsDisplay: React.FC = () => {
    const { threadFirstPosts, isLoading, isError } = useThreads();
    const [showNonStandardPosts, setShowNonStandardPosts] = useState(false);

    if (isLoading) return <Typography variant="h5">Loading</Typography>;
    if (isError) return <Typography variant="h5">Error</Typography>;

    return (
        <Container
            maxWidth="lg"
            sx={{
                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
            }}
        >
            <ScrollToTop />
            <ShowNonStandardButton
                showNonStandardPosts={showNonStandardPosts}
                setShowNonStandardPosts={setShowNonStandardPosts}
            />
            <Grid2
                container
                spacing={2}
                sx={{ width: "100%", display: "flex", flex: "1" }}
            >
                {threadFirstPosts.map(
                    (threadFirstPost: Post) =>
                        (!threadFirstPost.is_non_standard ||
                            showNonStandardPosts) && (
                            <Grid2
                                md={4}
                                sm={6}
                                xs={12}
                                sx={{ display: "flex" }}
                                key={threadFirstPost.no}
                            >
                                <ThreadPreview
                                    firstPost={threadFirstPost}
                                    key={threadFirstPost.no}
                                />
                            </Grid2>
                        )
                )}
            </Grid2>
        </Container>
    );
};
