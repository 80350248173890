import useSWR from "swr";
import { fetcher } from "../swr";
import { API_BASE } from "../config";
import { Post, ThreadPosts } from "./models";
import URI from "urijs";

export const useThreads = () => {
    let { data, error, isLoading } = useSWR(`/threads`, fetcher, {
        refreshInterval: 1000,
    });
    if (data) {
        data = (data as Post[]).sort((a, b) => b.no - a.no);
    }
    const threadFirstPosts: Post[] = (data as Post[])?.map((post) => ({
        ...post,
        is_non_standard: !/\/GME\/\s-\s.*/.test(post.sub || ""),
    }));

    return {
        threadFirstPosts,
        isLoading,
        isError: error,
    };
};

export const useThread = (threadNo: number) => {
    let { data, error, isLoading } = useSWR(`/thread/${threadNo}`, fetcher, {
        refreshInterval: 1000,
    });
    const threadPosts = data as ThreadPosts;
    return {
        threadPosts,
        isLoading,
        isError: error,
    };
};

export const getThreadFileURL = (
    threadNo: number,
    imageNo: number,
    fileName?: string,
    ext?: string
) => {
    let path = `file/${threadNo}/${imageNo}`;
    if (fileName) {
        path += `?filename=${fileName}`;
        if (ext) {
            path += ext;
        }
    }
    return URI(path).absoluteTo(API_BASE).toString();
};
